/**
 * EveryParts
 *
 * @author      Ukoo <help@every.parts> - https://www.ukoo.fr
 * @copyright   Ukoo 2024 - https://www.ukoo.fr
 * @license     see file: LICENSE.txt
 *
 * @version     1.8.2
 */
import {initUniversalTooltip, ucptScrollTo} from '../components/_utilities';

$(document).ready(() => {
  initUniversalTooltip();
});
